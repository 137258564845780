<template>
  <section class="list container">
    <div id="results">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>{{ title }}</h2>
        <ToggleArrow v-if="toggle === true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
        </ToggleArrow>
      </div>
      <div v-if="toggleMe && items.length === 0" style="margin-left: 50px">
        <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            {{ i18n['data-table-member-churches'].tcNoRecordsFound }}
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
      </div>
      <div v-if="items.length > 0">
        <header v-if="toggleMe && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(csvUrl, reportName, 'csv')">
              {{ i18n['data-table-member-churches'].tcExportCSV }}
            </a>
            <b-form-input v-model="filter" @input="handleFilterChange"
              :placeholder="i18n['data-table-member-churches'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="toggleMe" class="table-responsive">
          <b-table class="g-table" :fields="fields" :items="filteredItems" :per-page="perPage"
            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true"
            @sort-changed="handleSort" sort-icon-left small>
            <div class="d-inline-flex align-items-start" slot="HEAD_Media" slot-scope="data" @click="log(data)">
              <b-button v-if="data.field.cTooltip" id="button-1" class="tooltip-button mr-1" style="top: -7px">?
              </b-button>
              <b-tooltip v-if="data.field.cTooltip" target="button-1" :title="data.field.cTooltip"></b-tooltip>
              {{ data.label }}
            </div>
            <template v-slot:cell(vin_complete_name)="data">
              <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(sort_name)="data">
              <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data)">{{
              data.item.vin_complete_name || data.item.name
              }}</a>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button size="sm" variant="primary" @click="handleEditClick(data)" v-if="handleEditAction"
                class="flex-0 mr-3 w-100-sd mb-sm-0">{{ i18n['data-table-member-churches'].tcEdit }}</b-button>
              <b-button size="sm" variant="tertiary" @click="handleViewClick(data)" v-if="handleViewAction"
                class="flex-0 w-100-sd mb-sm-0 btn-sm">{{ i18n['data-table-member-churches'].tcView }}</b-button>
              <b-button size="sm" variant="secondary" @click="handleDeleteClick(data)" v-if="handleDeleteAction"
                class="flex-0 w-100-sd mb-sm-0 btn-sm">{{ i18n['data-table-member-churches'].tcDelete }}</b-button>
            </template>
            <template v-slot:cell(speaker)="data">
              <a href="../../user/user-profile/">{{ data.value }}</a>
            </template>
            <div slot="date_qualified" slot-scope="data">
              <span v-if="type(data.value) == 'string'">{{ data.value }}</span>
              <span v-if="type(data.value) === 'object'" :id="notUndefined(data.value.id)"
                :class="{ tooltip_text: data.value.tooltip }">{{ data.value.text }}</span>
              <b-tooltip v-if="data.value.tooltip && type(data.value) === 'object'" :target="data.value.id"
                :title="data.value.tooltip"></b-tooltip>
            </div>

            <span slot="Media" slot-scope="data">
              <div class="icon-box" :class="{ checked: data.value }"></div>
            </span>

            <span slot="History" slot-scope="data">
              <a :href="data.value">{{ i18n['data-table-member-churches'].tcView }}</a>
            </span>

            <template v-slot:cell(documents)="data">
              <div class="d-flex">
                <div style="margin-right: 10px" v-for="(file, index) in data.value" :key="index">
                  <a v-if="file.type === 'pdf'" :href="file.url" title="PDF">
                    <iFile></iFile>
                  </a>
                  <a v-if="file.type === 'csv'" :href="file.url" title="CSV">
                    <iCSV></iCSV>
                  </a>
                  <a v-if="file.type === 'excel'" :href="file.url" title="Excel">
                    <iExcel></iExcel>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </main>
        <footer v-if="toggleMe" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <i-arrow-left />
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table-member-churches'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :hide-goto-end-buttons="true"
                @change="pageChanged"></b-pagination>
            </div>
          </nav>
          <div class="next" @click="nextPage" :class="{
            hidden:
              Math.ceil(!!this.filteredItems ? this.filteredItems.length / this.perPage : this.currentPage) ==
              this.currentPage,
          }">
            <i-arrow-right />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import iCSV from '@/assets/svgs/iCSV.vue'
import iExcel from '@/assets/svgs/iExcel.vue'
import iFile from '@/assets/svgs/iFile.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'placement-list',
  mixins: [reportDownloadMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-member-churches': {
            tcActions: 'Actions',
            tcCampName: 'Camp Name',
            tcCampNumber: 'Camp Number',
            tcDelete: 'Delete',
            tcEdit: 'Edit',
            tcExportCSV: 'Export XLS',
            tcName: 'Name',
            tcSearch: 'Search',
            tcShow: 'Show',
            tcView: 'View',
            tcNoRecordsFound: 'No records were found.',
          },
        }
      },
    },
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    csvUrl: {
      type: String,
      default: '',
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    reportName: {
      type: String,
      default: 'Report',
    },
    addItems: {
      type: Object,
      default: {
        text: 'Add',
        display: true,
        action: '#',
      },
    },
    hideHeader: { type: Boolean, default: false },
    title: String,
    handleDeleteAction: { type: Boolean, default: false },
    handleEditAction: { type: Boolean, default: true },
    handleViewAction: { type: Boolean, default: true },
    toggle: Boolean,
  },
  created() {
    // Initialize the filtered item list to complete item set.
    this.filteredItems = [...this.items]
    this.filter = this.searchTerms.memberChurchesTerm
    this.perPage = constantData.per_page_default
  },
  data() {
    return {
      noPresentationsMessage: 'No records were found.',
      // Placement Table Settings
      sortBy: 'Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      toggleMe: true,
      filteredItems: null,
      filtering: false,
      previousFilter: null,
      refilterRequired: false,
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setSearchTerm: 'search/setSearchTerm',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (
        Math.ceil(!!this.filteredItems ? this.filteredItems.length / this.perPage : this.currentPage) ==
        this.currentPage
      ) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    log(item) { },
    type(val) {
      return typeof val
    },
    sortRecords(ctx) {
      let sortBy = !!ctx ? ctx.sortBy : this.sortBy
      let sortDesc = !!ctx ? ctx.sortDesc : this.sortDesc

      this.filteredItems.sort((a, b) => {
        let fieldA = a[sortBy]
        let fieldB = b[sortBy]

        if (!!fieldA) {
          fieldA = fieldA.toLowerCase()
        }

        if (!!fieldB) {
          fieldB = fieldB.toLowerCase()
        }

        let result = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0

        if (sortDesc) {
          result *= -1
        }

        return result
      })
    },
    filterRecords() {
      let availableRecords = undefined

      // Determine direction of filter update (more specific or less specific)
      if (
        (!this.previousFilter && !!this.filter) ||
        (!!this.previousFilter && !!this.filter && this.previousFilter.length <= this.filter.length)
      ) {
        // If going more specific, only currently filtered results are candidates to remain in
        // filtered set, so only use current filtered results for base of updated filter application.
        availableRecords = this.filteredItems
      } else {
        // Because the filter is less specific, entries not included in the existing filtered set
        // should be included, so use all avaiable records for base of updated filter application.
        availableRecords = [...this.items]
      }

      // If the filter has been cleared, there is no need to calculate filtered record set
      // as records would be returned; short-circuit filter process to include all records.
      if (!this.filter || this.filter.length === 0) {
        this.filteredItems = availableRecords
      } else {
        // Apply the filter, using basic string contains conditional.
        let displayedFields = this.fields
        let searchText = this.filter.toLowerCase()

        this.filteredItems = availableRecords.filter((rec) => {
          let include = false

          // Only search displayed fields, and short-circuit search as soon as a match occurs.
          for (let index = 0; !include && index < displayedFields.length; index++) {
            let recValue = rec[displayedFields[index].key]
            include = !!recValue && recValue.toLowerCase().indexOf(searchText) > -1
          }

          return include
        })
      }

      // Update the previous filter to track the current value used to prepare for a future filter change.
      this.previousFilter = this.filter

      // Apply record sorting
      this.sortRecords()

      // Update the current page to the first page of the record set.
      this.currentPage = 1

      // Using poor man's locking structure; if a filter update was requested while the
      // current filter processing occurred, perform the process again, as additional
      // changes have occurred.
      if (this.refilterRequired) {
        this.refilterRequired = false
        this.filterRecords()
      }
    },
    handleEditClick(data) {
      this.$emit('handleEditClick', data)
    },
    handleDeleteClick(data) {
      this.$emit('handleDeleteClick', data)
    },
    async handleFilterChange() {
      await this.setSearchTerm({ memberChurchesTerm: this.filter })
      // Using poor man's lock by using two flag properties.
      if (!this.filtering) {
        this.filtering = true
        this.filterRecords()
        this.filtering = false
      } else {
        this.refilterRequired = true
      }
    },
    handleSort(ctx) {
      this.sortRecords(ctx)
      this.currentPage = 1
    },
    handleViewClick(data) {
      this.$emit('handleViewClick', data)
    },
    notUndefined(val) {
      return !val ? '' : val
    },
    async individualStoreKeyAndRedirect(data) {
      await this.setSelectedIndividualKey(data.item.vin_ind_key || data.item.ind_key)
      this.$router.push({ name: 'member-profile' })
    },
  },
  computed: {
    ...mapGetters({
      searchTerms: 'search/searchTerms',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
    }),
    rows() {
      return !!this.filteredItems ? this.filteredItems.length : 0
    },
  },
  components: {
    iArrowLeft: iArrowLeft,
    iArrowRight: iArrowRight,
    iCSV: iCSV,
    iExcel: iExcel,
    iFile: iFile,
    ToggleArrow: ToggleArrow,
  },
  watch: {
    items: function () {
      // Whenever the base item list is modified the filter process should be applied.
      this.filterRecords()
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.preheader {
  padding: 30px 32px 15px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.lower {
  text-transform: lowercase;
}
</style>
